import React from "react";
import { Typography } from "@cumplo/design-system";
import * as styles from "./styles.module.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function AnnouncementBar() {
  return (
    <div className={styles.containerAnnouncement}>
      <ErrorOutlineIcon className={styles.iconAlert} />
      <Typography className={styles.text} variant="p">
        Nuestros sistemas estarán en mantenimiento desde el 17 de septiembre a
        las 14:00 hasta el día siguiente.
      </Typography>
    </div>
  );
}
